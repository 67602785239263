import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'

export default ({open, handler, location, language }) => {
  const data = useStaticQuery(queryScheme);
  
  return (
  <Wrapper open={open}>
    <Nav>
      {data.allPrismicUvod && data.allPrismicUvod.edges
        .filter((edge) => edge.node.lang.includes(language))
        .map((edge) => (
          <Link
            to={`/${edge.node.lang.substring(0,2)}/`}
            onClick={handler}
            key={edge.node.id}
            className={location.pathname === `/${edge.node.lang.substring(0,2)}/` ? 'active' : ''}
            >
            <Title>{edge.node.data.nadpis_odkazu.text ? edge.node.data.nadpis_odkazu.text : edge.node.data.nadpis.text}</Title>
            <Subtitle>{edge.node.data.popis_odkazu.text ? edge.node.data.popis_odkazu.text : edge.node.data.popis.text}</Subtitle>
        </Link>
      ))}

      {/* other pages */
      data.allPrismicStranka && data.allPrismicStranka.edges
        .filter((edge) => edge.node.lang.includes(language))
        .map((edge) => (
          <Link
            to={`/${edge.node.lang.substring(0,2)}/${edge.node.uid}/`}
            onClick={handler}
            key={edge.node.id}
            activeClassName="active"
            >
          <Title>{edge.node.data.nadpis_odkazu.text ? edge.node.data.nadpis_odkazu.text : edge.node.data.nadpis.text}</Title>
          <Subtitle>{edge.node.data.popis_odkazu.text ? edge.node.data.popis_odkazu.text : edge.node.data.popis.text}</Subtitle>
        </Link>
      ))}
    </Nav>
  </Wrapper>
)}

const queryScheme = graphql`
  query MenuQuery {
    allPrismicStranka(
      sort: { fields: [data___poradi], order: ASC }
    ) {
      edges {
        node {
          id
          uid
          lang
          alternate_languages {
            id
            lang
          }
          data {
            nadpis {
              html
              text
            }
            popis {
              html
              text
            }
            nadpis_odkazu {
              html
              text
            }
            popis_odkazu {
              html
              text
            }
            poradi
          }
        }
      }
    }
    allPrismicUvod {
      edges {
        node {
          id
          lang
          alternate_languages {
            id
            lang
          }
          data {
            nadpis {
              html
              text
            }
            popis {
              html
              text
            }
            nadpis_odkazu {
              html
              text
            }
            popis_odkazu {
              html
              text
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100vw;
min-height: 100vh;
z-index: -2;
display: none;
animation: fadeIn .4s ease-out;

display: ${props => props.open ? 'flex' : 'none'};
justify-content: center;
&:before {
  content: '';
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #012967;
  z-index: -2;

}
`

const Nav = styled('nav')`
padding: 2rem;
max-width: 20em;
min-height: 100%;
margin-top: 3rem;

display: flex;
flex-direction: column;
justify-content: center;
a {
  transition: opacity .2s ease;
  &:hover {
    opacity: .7 !important;
  }
  &.active {
    opacity: .3 !important;
    // cursor: default;
  }
}

`

const Title = styled('h4')`
color: white;
text-transform: uppercase;
margin: .5rem 0;
`

const Subtitle = styled('p')`
`
