import React, {useState, useContext} from 'react'
import {Link} from 'gatsby-link'
import styled from '@emotion/styled'
import {useStaticQuery, graphql} from 'gatsby'

import Menu from '../components/Menu'

import { LanguageContext } from '../layouts/index'

export default ({location}) => {
  const data = useStaticQuery(queryScheme);
  const { language, updateLanguage } = useContext(LanguageContext)
  const [open, setOpen] = useState(false);

  const currentLocation = location.pathname

  // language magic ✨✨✨
  const alternateSlugs = {}
  const currentSlug = currentLocation.split('/').filter(e => e !== '').pop()
  const currentNode = data.allPrismicStranka.edges.filter(page => page.node.uid === currentSlug)
  
  if (currentNode[0]) currentNode[0].node.alternate_languages.forEach(language => {
    const key = language.lang.substring(0,2)
    alternateSlugs[key] = language?.uid
  })
    
    return (
      <Navbar open={open}>
        <Toggler onClick={() => setOpen(!open)} open={open}>
          <div />
          <div />
          <div />
        </Toggler>
        <Menu open={open} handler={() => setOpen(false)} location={location} language={language}/>
        <Language onClick={() => setOpen(false)}>
          {['cs','en','de'].map(l => (
            <span key={l}>
              <Link
              to={alternateSlugs[l]? `/${l}/${alternateSlugs[l]}` : currentLocation.replace(language, l)}
              className={language === l ? 'active' : ''}
              onClick={() => updateLanguage(l)}
            >
              {l}
            </Link>{' '}
            /&nbsp;
          </span>
          ))}
        </Language>
      </Navbar>
    )
  }


const queryScheme = graphql`
  query HeaderQuery {
    allPrismicStranka(
      sort: { fields: [data___poradi], order: ASC }
    ) {
      edges {
        node {
          id
          uid
          lang
          alternate_languages {
            lang
            uid
          }
          data {
            nadpis {
              html
              text
            }
            popis {
              html
              text
            }
            nadpis_odkazu {
              html
              text
            }
            popis_odkazu {
              html
              text
            }
            poradi
          }
        }
      }
    }
  }
`

const Navbar = styled('nav')`
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 999;

  * {
    color: ${props => props.open ? 'white': 'black'} !important;
  }
`

const Toggler = styled('button')`
  div {
    border-top: 3px solid ${props => props.open ? 'white': 'black'};
    margin-bottom: 3px;
    transition: all .2s ease-in;

    &:nth-of-type(1) {
      width: 17px;
      ${props => props.open && 'transform: rotate(135deg) translateX(4px) translateY(-1px); width: 10px'};
    }
    &:nth-of-type(2) {
      width: ${props => props.open ? '15px' : '12px'};
    }
    &:nth-of-type(3) {
      width: 15px;
      ${props => props.open && 'transform: rotate(-135deg) translateX(4px) translateY(1px); width: 10px'};
    }
  }
`

const Language = styled('div')`
  font-family: 'Brandon Grotesque', sans-serif;
  text-transform: uppercase;

  .active {
    font-weight: 600;
  }
`
