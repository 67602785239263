import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

import Header from "../components/Header"
import "./base.scss"

export const LanguageContext = React.createContext()

const Layout = ({ location, children }) => {
  const [language, updateLanguage] = useState("en")

  // Adjust language according to slug
  useEffect(() => {
    const slug = location.pathname.substr(1, 2)
    if (slug === "cs" || slug === "en" || slug === "de") updateLanguage(slug)
  }, [location])

  const cs = language === "cs"
  const en = language === "en"
  const de = language === "de"

  return (
    <div>
      <LanguageContext.Provider
        value={{
          language,
          updateLanguage,
        }}
      >
        <Header location={location} />
        <div
          style={{
            margin: "0 auto",
            maxWidth: 960,
            padding: "0px 1.0875rem 1.45rem",
            paddingTop: 0,
          }}
        >
          {children}
        </div>
        <Signature>
          {cs
            ? "V případě dotazů nás kontaktujte na "
            : en
            ? "If you have a question, contact us at "
            : "Bei weiteren Fragen melden Sie sich bei uns "}
          <a href="mailto:nordheimbua@gmail.com">nordheimbua@gmail.com</a>
          <br />
          <br />
        </Signature>
      </LanguageContext.Provider>
    </div>
  )
}

const Signature = styled.div`
  text-align: center;
  margin: 2rem auto;
  a {
    font-weight: 600;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`

export default Layout